<template>
  <div id="page-text-fields">
    <v-container grid-list-xl>
      <v-layout row wrap>
        <v-flex sm12>
          <v-form ref="form">
            <v-widget :title="'Hi ' + user.name">
              <div slot="widget-content">
                <v-container>
                  <v-layout>
                    <v-flex xs4>
                      <v-layout row>
                        <v-flex class="d-flex justify-center" id="pick-avatar">
                          <v-avatar size="200">
                            <v-img
                              width="200px"
                              height="200px"
                              max-width="200px"
                              max-height="200px"
                              :src="this.user.picture"
                              lazy-src="static/avatar.png"
                              id="pick-avatar"
                              @click="_onSelectButtonClick"
                            />
                          </v-avatar>

                          <input
                            style="display: block"
                            ref="imageInput"
                            type="file"
                            accept="image/x-png,image/gif,image/jpeg"
                            name="image"
                            @change="onChange"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs8>
                      <v-layout row>
                        <v-flex xs12>
                          <v-text-field
                            label="Name"
                            v-model="user.name"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row>
                        <v-flex xs12>
                          <v-text-field
                            label="Email"
                            v-model="user.email"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <v-btn
                        class="mt-5 btn_gradient"
                        @click="_callUpdateUser"
                        :loading="loading"
                      >
                        Save
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>
            </v-widget>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
    <CropImageDialog
      title="Crop Image"
      :show="isCropImageDialogVisible"
      :image="imgSrc"
      :dismiss-action="_closeCropDialog"
      :on-image-cropped="_onImageCropped"
    />
  </div>
</template>

<script>
import VWidget from '@/components/VWidget';
import { mapGetters, mapActions } from 'vuex';
import CropImageDialog from '@/components/dialogs/CropImageDialog';
import firebase from 'firebase';

export default {
  components: {
    CropImageDialog,
    VWidget
  },
  data() {
    return {
      isCropImageDialogVisible: false,
      user: {},
      imgSrc: null,
      bannerUrl: null,
      videoUrl: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['getUser']),
    confirmPasswordRule() {
      return [
        (value) => (!!value && value.trim().length > 0) || 'Required.',
        () =>
          this.model.confirmPassword === this.model.password ||
          'Password and Confirmation Password must match'
      ];
    }
  },
  methods: {
    ...mapActions({
      _updateUser: 'updateUser'
    }),

    async _callUpdateUser() {
      this.loading = true;
      console.log(this.user);
      await this._updateUser(this.user);
      this.loading = false;
      await this.$router.push({ path: '/' });
    },

    onChange(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.isCropImageDialogVisible = true;
          this.imgSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    _onSelectButtonClick() {
      this.$refs.imageInput.click();
    },
    async _onImageCropped(file) {
      try {
        const snapshot = await firebase
          .storage()
          .ref(`avatar/${this.user.name}`)
          .put(file);
        this.user.picture = await snapshot.ref.getDownloadURL();
        this.isCropImageDialogVisible = false;
      } catch (e) {
        console.log(e);
      }
    },

    _closeCropDialog() {
      this.isCropImageDialogVisible = false;
    },

    async _onFileSelected(file) {
      try {
        const snapshot = await firebase
          .storage()
          .ref(`${file.type}/${file.name}`)
          .put(file.file);
        const fileUrl = await snapshot.ref.getDownloadURL();
        console.log(file.type);
        if (file.type === 'image') {
          this.user.banner = fileUrl;
          this.bannerUrl = fileUrl;
        } else {
          this.user.video = fileUrl;
          this.videoUrl = fileUrl;
        }
      } catch (e) {
        console.log(e);
      }
    },

    _onRemoved() {
      this.show.picture = '';
    }
  },
  mounted() {
    this.user = this.getUser;
  }
};
</script>

<style>
input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.theme--light.v-sheet {
  background-color: rgb(255 255 255 / 50%);
  border-radius: 30px;
  box-shadow: none !important;
}
.theme--light.v-sheet .white {
  background-color: transparent !important;
}
.v-toolbar__title h4 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  color: #fff;
  margin-top: 5px;
  margin-left: 6px;
}
</style>
